@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;700&family=Kadwa:wght@400;700&family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');


p {
    font-family: 'Lato';
    color: #000000;
}
label {
    font-family: 'Assistant';
    color: #000000;
    padding-bottom: 20px;
}

h1, h2 {
    font-family: 'Kadwa';
    color: #000000;
}

h3, h4 {
    font-family: 'Assistant';
}

NavBar {
    background-color: #ffffff;
    align-items: center;
}

body {
    background-color: #ffffff;
}

button {
    background-color: #99CC33;
    border-color: transparent;
    border-radius: 20px;
    font-family: 'Lato';
    font-weight: bold;
    padding: 5px;
    box-shadow: 2px 2px 5px #666666;
}

button:hover {
    opacity: 75%;
}

.brandContainer {
    color: #666666;
    background-color: #ffffff;
}

.loginButtonContainer .adminButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
    margin: 10px;
}

.navBar {
    display: flex;
}

.navLogo {
    margin-right: 10px;
}

.brandContainer {
    display: flex;
    padding: 0 20px;
}

.loginContainer .adminButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.linkContainer {
    display: flex;
    justify-content: space-evenly;
    width: 200px;
}

.linkContainer a {
    color: white;
    text-decoration: none;
}

.buttonSecondary {
    background-color: #F79D2F ;
    box-shadow: 2px 2px 3px #666666;
}


.buttonSecondary:active{
    background-color: #C47D25;
    opacity: 100%;
    
}

.buttonPrimary:active {
    background-color: #739926;
    opacity: 100%;
}

.submitButtonPrimary {
    background-color: #99CC33;
    border-color: transparent;
    border-radius: 50px;
    font-family: 'Lato';
    font-weight: bold;
    padding: 5px 25px 5px 25px;
    font-size: 125%;
    box-shadow: 2px 2px 5px #666666;
}

.submitButtonPrimary:hover {
    opacity: 50%;
}

.submitButtonPrimary:active {
    background-color: #739926;
    opacity: 100%;
}

.selectionList {
    font-size: 24px;
}

  .permissions-table {
    width: 25%;
    border-collapse: collapse;
  }
  
  .permissions-table th,
  .permissions-table td {
    padding: 3px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .permissions-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .permissions-table tr:hover {
    background-color: #ddd;
  }

  .help-text {
    font-size: 0.8em;
    color: #888;
    font-style: italic;
    margin-top: 5px;
  }

  .successor {
    margin-bottom: 10px;
  }