.App {
 background-color: #666666;
}

.App-header {
    display: flex;
    min-height: 75vh;
    align-items: center;
    font-size: calc(10px + 1.5vmin);
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.allRankingBoxes {
    display: flex;
    flex-direction: row;
}

.rankedMembers {
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 25px;
    margin-right: 25px;
    margin-bottom: 25px;
}

.unrankedMembers {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    background-color: transparent;
}
.vertArrow {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.vertHeaderColumn {
    display: grid;
    grid-template-rows: repeat(3, .3fr);
}
.vertHeader{
    display: flex;
    transform: rotate(-90deg);
    font-size: 75%;
    align-self: center;
}

.vh1 {
    flex-direction: row;
    align-self: center;
}

.horizHeader1, .horizHeader2, .horizHeader3 {
    font-size: 75%;
}

.rank1, .rank5, .rank9 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 150px;
    border-radius: 5px;
    background: #31C4A5;
}
.rank2, .rank6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 150px;
    border-radius: 5px;
    background: #C1E084;
}
.rank3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 150px;
    border-radius: 5px;
    background: #99CC33;
}
.rank4, .rank8 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 150px;
    border-radius: 5px;
    background: #95F2F8;
}

.rank7 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 150px;
    border-radius: 5px;
    background: #F8B058;
}

.teamMembers
tbody[class="teamMembers"]
tr[style="cursor: default;"]:hover
td:last-child:after {
    color: red;
    content: " THIS ROW IS NOT DRAGGABLE";
    font-size: 16px;
    font-weight: 800;
}


.teamMembers li {
    display: flex;
    flex-direction: "column";
    align-items: center;
    background: #D9D9D9;
    border-radius: .2em;
    padding: .5em .8em .5em .5em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 1em;
}

.teamMembers p {
    max-width: none;
    font-weight: normal;
    font-size: medium;
    margin: 0;
    align-items: center;
    text-align: center;
}

.teamMembers-thumb {
    overflow: hidden;
    flex-shrink: 0;
    width: 2em;
    height: 2em;
    background-color: #e8e8e8;
    padding: .5em;
    margin-right: .5em;
}

.capitalizeName {
    text-transform: capitalize;
}

.teamMembers-thumb img {
    display: block;
    width: 100%;
    height: auto;
}

.modalHeader {
    color: #ffffff;
    text-shadow: 2px 2px 4px #000000;
    margin: 5px 0 5px 0;
}

.modalText {
    color: #ffffff;
    text-shadow: 2px 2px 4px #000000;
    margin: 5px 0 5px 0;
}

#modalX {
    padding-left: 375px;
}

.teamMembersHidden {
    display: none;
}

.teamMembersShown {

}

.horizArrow {
    flex-direction: row;
    align-items: center;
}

.successorCrown {
    color: rgb(30,48,80);
    margin-left: 10px;
}